@import url('https://fonts.googleapis.com/css?family=Tangerine');
@import url('https://fonts.googleapis.com/css?family=Dosis:wght@200;300;400;500;600&&family=Lora:ital,wght@0,400;0,500;1,400;1,500');
@tailwind base;
@tailwind components;
@tailwind utilities;

#logo-container {
  padding: 20px;
  background: #20252a;
  color:#fff;
  overflow: hidden;
  position: relative;
  min-height: 120px;
} 

#logo-background {
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('background-logo.jpg');
}

#logo-background:before {
  opacity: .88;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(33, 38, 44 ,.88);
  transition: all .2s linear;
}

#logo-title {
  padding: 35px;
  z-index: 55;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}